import '../wdyr';
import { navigate } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { isLoggedIn, clearClientData } from '../services/auth';
import SEO from '../components/seo';
import Banner from '../components/shared/hero';
import Agenda from '../components/agenda';
import SpeakerCard from '../components/speakers/SpeakerCard';
import SpeakerDescription from '../components/speakers/SpeakerDescription';
import LiveEvent from '../components/dashboard/components/liveEvent';
import { LiveEventImage } from '../data/global';
import { getNestedObjectValue } from '../utils/global';
import { TOKEN_EXPIRED } from '../utils/constants';

const DashboardPage = () => {
  const [openState, setOpenState] = useState(false)
  const [descriptionWidth, setDescriptionWidth] = useState(0)
  const [dynamicStyles, setDynamicStyles] = useState({})
  const speakersContainer = useRef(null)



  const defineDescriptionWidth = () => {
    if (speakersContainer.current) {
      if (window.innerWidth > 1440) {
        setDescriptionWidth(speakersContainer.current.offsetWidth / 2 - 30)
      } else if (window.innerWidth > 1199) {
        setDescriptionWidth(
          (speakersContainer.current.offsetWidth / 5) * 3 - 30
        )
      } else if (window.innerWidth > 1023) {
        setDescriptionWidth(speakersContainer.current.offsetWidth / 2 - 30)
      } else if (window.innerWidth > 350) {
        setDescriptionWidth(
          (speakersContainer.current.offsetWidth / 3) * 2.75 - 30
        )
      }
    }
  }

  const closeAllCards = () => {
    defineDescriptionWidth()

    if (speakersContainer.current) {
      speakersContainer.current.querySelectorAll(".active").forEach(card => {
        card.classList.remove("active")
      })
    }
    setOpenState(false)
  }

  const handleCardClick = e => {
    if (!e.currentTarget.classList.contains("active")) {
      closeAllCards()
      e.currentTarget.classList.add("active")
      setOpenState(true)
      const offset =
        speakersContainer.current.offsetWidth -
        15 -
        e.currentTarget.offsetLeft -
        descriptionWidth
      offset < 0
        ? setDynamicStyles({
          width: `${descriptionWidth}px`,
          marginLeft: `${offset}px`,
        })
        : setDynamicStyles({ width: `${descriptionWidth}px` })
    } else {
      closeAllCards()
    }
  }

  const handleCloseClick = e => {
    e.preventDefault()
    closeAllCards()
  }



  useEffect(() => {
    defineDescriptionWidth()
    window.addEventListener("resize", closeAllCards)
  }, [])


  const [agendaData, setagendaData] = useState({
    days: [],
    presentations: [],
    activeTabIndex: 0,
    title: 'Agenda',
  });

  const {
    isLoading,
    error,
    data = {
      result: {
        agenda: {
          title: 'Agenda',
          days: [],
        },
        presentations: [],
        activeTabIndex: 0,
      },
    },
  } = useQuery('agenda', () => fetch(`${process.env.API_URL}/${process.env.EVENT_ID}/agenda`).then((res) => res.json()));
  const [speakersData, setSpeakersData] = useState({});
  const [liveWebcastData, setLiveWebcastData] = useState(null);

  const getAgendaData = async () => {
    const response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/agenda`,
    );
  
    const data = await response.json();
    if (data && data.status === 200 && data.result) {
      setagendaData({
        activeTabIndex: data.result.activeTabIndex,
        days: data.result.agenda.days,
        title: data.result.agenda.agenda_title,
        presentations: data.result.presentations,
      });
    }
  };

  const getSpeakersData = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/speakers`,
    );
    response = await response.json();

    if (response && response.status === 200 && response.result) {
      setSpeakersData(response.result);
    }
  };

  const fetchLiveEventDetails = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/live`,
    );
    response = await response.json();
    if (response.result) {
      const startTime = getNestedObjectValue(response, 'result.start_time');
      const endTime = getNestedObjectValue(response, 'result.end_time');
      const webcastTitle = getNestedObjectValue(response, 'result.name');
      const webcastDesc = getNestedObjectValue(
        response,
        'result.presentation_desc',
      );
      const speakers = getNestedObjectValue(response, 'result.speakers');
      setLiveWebcastData({
        webcastTime: startTime && endTime ? `${startTime} - ${endTime}` : '',
        webcastTitle: webcastTitle || '',
        webcastDesc:
          (webcastDesc && webcastDesc.length > 110 ? `${webcastDesc.substring(0, 110)}...` : webcastDesc),
        webcastSpeaker: speakers || [],
      });
    }
    if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
      clearClientData();
    }
  };

  const closeSpeakerDescriptionBox = e => {
    const clickedWithinSpeakerDescriptionBox = e.target.closest(
      ".speaker-description"
    );
    if (openState && !clickedWithinSpeakerDescriptionBox) {
      closeAllCards();
    }
  };

  useEffect(() => {
    // getAgendaData()
    getSpeakersData();
    fetchLiveEventDetails();
    document.addEventListener("click", closeSpeakerDescriptionBox);

    return () => {
      document.removeEventListener("click", closeSpeakerDescriptionBox);
    };
  }, [openState]);

  if (typeof window !== 'undefined' && !isLoggedIn()) {
    navigate('/');
    return null;
  }
  return (
    <>
      <div className="page dashboard">
        <SEO title="Dashboard" />

        <Banner />

        {liveWebcastData && (
          <div className="card-wrapper background-color-monochrome-1 mt-20 color-secondary-2">
            <div className="card-title background-color-primary-1">
              <div className="pt-10 pb-10 pr-20 pl-20 d-xs-flex xs-middle">
                <h4 className="mb-0 text-size-h5 font-primary-bold color-monochrome-1">
                  Live event
              </h4>
              </div>
            </div>
            <LiveEvent
              image1={LiveEventImage}
              authorImages={liveWebcastData.webcastSpeaker}
              time={liveWebcastData.webcastTime}
              name={liveWebcastData.webcastTitle}
              description={liveWebcastData.webcastDesc}
            />
          </div>
        )}
        <div className="card-wrapper mt-20">
          <div className="card-title background-color-primary-1">
            <div className="pt-10 pb-10 pr-20 pl-20 d-xs-flex xs-between xs-middle">
              <h4 className="mb-0 text-size-h5 font-primary-bold color-monochrome-1">
                {data?.result?.agenda?.agenda_title}
              </h4>
              <div className="text text-size-medium color-monochrome-1 font-secondary-regular">
                <button 
                  className="button font-secondary-bold"
                  onClick={() => navigate('/agenda')}>
                    View full agenda
                </button>
              </div>
            </div>
          </div>
          <div className="agenda-block pl-20 pr-20 pt-20 pb-20">
            <Agenda
              days={data?.result?.agenda.days ? data?.result?.agenda.days : []}
              presentations={data?.result?.presentations}
              startIndex={data?.result?.activeTabIndex}
              max={4}
            />
          </div>
        </div>

        <div className="card-wrapper mt-20">
          <div className="card-title background-color-primary-1">
            <div className="pt-10 pb-10 pr-20 pl-20 d-xs-flex xs-between xs-middle">
              <h4 className="mb-0 text-size-h5 font-primary-bold color-monochrome-1">
                Speakers
              </h4>
              <div className="text text-size-medium color-monochrome-1 font-secondary-regular">
                <button 
                  className="button font-secondary-bold"
                  onClick={() => navigate('/speakers')}>
                    View all speakers
                </button>
              </div>
            </div>
          </div>
          <div className="speakers-block pl-20 pr-20 pt-20">
            <div className={`speakers d-xs-flex xs-wrap ${openState ? "active" : ""}`} ref={speakersContainer}>

              {speakersData
                && speakersData.speakers
                && speakersData.speakers.map((speaker, idx) => {
                  if (idx < 6) {
                    return (
                      <div className="speaker" key={idx}>
                        <SpeakerCard
                          name={speaker.speaker_name}
                          imgSrc={speaker.speaker_image_path}
                          // company={speaker.speaker_company}
                          jobTitle={speaker.speaker_job_title}
                          onClick={handleCardClick}
                        />
                        <SpeakerDescription
                          styles={dynamicStyles}
                          onClose={handleCloseClick}
                          name={speaker.speaker_name}
                          description={speaker.speaker_desc}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DashboardPage);
