import React from "react"
import { navigate } from "gatsby"

const LiveEvent = props => {
  return (
    <div className="live-event-block">
      <div className="row">
        <div className="col-sm-8">
          <div className="media">
            <div className="image image--background">
              <img src={props.image1} className="img" alt="Live Event" />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="pr-20 pl-xs-20">
            <div className="row">
              <div className="col-md-9">
                <div className="live-event-desc mt-20">
                  <div className="live-event-title font-secondary-bold mb-15 mb-xs-5">
                    {props.time}
                  </div>
                </div>
                <div className="font-secondary-bold color-monochrome-2 pl-20">{props.name}</div>
                {!!props.description && (
                  <div
                    className="font-secondary-regular"
                    dangerouslySetInnerHTML={{ __html: props.description }}
                  ></div>
                )}
                <div className="d-xs-flex xs-middle mt-20">
                  <div className="mr-25 mr-xs-20">
                    {props.authorImages.map(value => {
                      return (
                        <img
                          className="radius-circle mr-10 mb-10"
                          src={value.speaker_image_path}
                          height="50px"
                          width="50px"
                          alt={"Image of " + value.speaker_name}
                        />
                      )
                    })}
                  </div>
                </div>
                <div className="mt-20 mb-30 mb-xs-20 pl-20">
                  <button 
                    className="button font-secondary-bold _a_watchnow color-primary-1"
                    onClick={() => navigate('/live')}>
                      Watch now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(LiveEvent)
